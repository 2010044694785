import React, { lazy } from 'react'

const BIGraphDetail = lazy(() =>
  import('./BIGraphDetail').then(module => ({
    default: module.BIGraphDetail,
  }))
)

const BusinessInsightRoutes = [
  {
    props: {
      exact: true,
      path: '/BusinessInsightManagementListingV2/BIGraphDetail',
    },
    component: <BIGraphDetail />,
  },
]

export default BusinessInsightRoutes
