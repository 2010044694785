import React, { lazy } from 'react'


const BusinessInsightManagementListingV2 = lazy(() =>
  import('./BusinessInsightManagement/BusinessInsightManagementListingV2').then(
    module => ({
      default: module.BusinessInsightManagementListingV2,
    })
  )
)

const HomeRoutes = [
  {
    props: { exact: true, path: '/BusinessInsightManagementListingV2' },
    component: <BusinessInsightManagementListingV2 />,
  },
  
]

export default HomeRoutes
